
.sidebar-page_active{
  border: 1px solid red;
}

.sidebar {
  --border: 1px solid #dee2e6;
  --sidebar-header-hover-clr: #212529;
  background-color: #f8f9fa;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2),
              0px 6px 20px 0px rgba(0, 0, 0, 0.19);
              position: absolute;  display:grid;

              z-index: 10;

}
.router-container {
  height: calc(100% - 100px);
  overflow-y: auto;
}


.sidebar--right
{
  right:0; top:0; bottom:0; width: 65px;
}

.sidebar--left
{
  left:0; top:0; bottom:0; width: 65px;
}

.sidebar--bottom
{
  left:0; right:0; bottom:0; height: 50px;
}

.sidebar--top
{
  left:0; right:0; bottom:0; height: 65px;
}

.sidebar ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}


.sidebar.sidebar--bottom ul,
.sidebar.sidebar--top ul
{
  flex-direction: row;
  justify-content: space-evenly;
}

.sidebar.sidebar--left ul,
.sidebar.sidebar--right ul
{
  flex-direction: column;
}



.sidebar.sidebar--left ul .last-item,
.sidebar.sidebar--right ul .last-item
{
  margin-top: auto;
}



.sidebar .nav-item {
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.sidebar .nav-item button:not(:disabled):hover,
.sidebar .nav-item button:active {
color: var(--blue);
}

.sidebar.sidebar--bottom .nav-item {
  aspect-ratio: unset;
}



.sidebar.sidebar--left ul .nav-item,
.sidebar.sidebar--right ul .nav-item
{
  border-top: var(--border);
}




.sidebar .nav-item.sidebar-page_active{
  background: linear-gradient(180deg,#f0f9ff 0,#bfd6e4);
  border: 1px solid #90b5d5;
}



.sidebar-drawer{
  width: 100%;

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
/* CSS rules for extra small devices here */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
.sidebar-drawer{
  width: 100%

}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
.sidebar-drawer{
  width: 100%

}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
.sidebar-drawer{
  width: 400px;


}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
.sidebar-drawer{
  width: 600px;

}

}
