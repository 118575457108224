.tab {
  --_tab-bg: #7692FF;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--_tab-bg);
  width: 200px;
  padding-block: 0.25rem;
  padding-inline: 1rem;
  border-radius: 8px 8px 0 0 ;
  display: grid;
  justify-content: center;
}

.tab.tab--bottom {
  bottom: 0;
  left: 50%;
}

.tab:not(.tab--bottom)::before,
.tab:not(.tab--bottom)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 90%;
  width: 90vw;
  height: 5px;
  background-color: var(--_tab-bg);
  z-index: 1;
}

.tab:not(.tab--bottom)::after {
  left: auto;
  right: 90%;
}

.tab > button {
  background-color: unset;
  width: 50px;
  cursor: pointer;
  border: none;
}

.tab i {
  color: white;
}

body .mat-bottom-sheet-container {
  min-width: 100vw;
  padding:unset;
  overflow: unset;
  border-radius: unset;
  min-height: 25dvh;
}

.p-datatable .p-datatable-thead > tr > th {
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  text-align: center;
}

.p-datatable .p-datatable-tbody > tr > td {
  border-right: 1px solid #dee2e6;
}

.p-datatable .p-datatable-thead > tr > th:last-child,
.p-datatable .p-datatable-tbody > tr > td:last-child {
  border-left: 1px solid #dee2e6;
}

.p-datatable .p-datatable-tbody > tr {
  border-bottom: 1px solid #dee2e6;
}

p-table {
  display: block;
}

.p-datatable {
  height: 100%;
  font-size: 0.6rem;
}

.p-datatable .p-datatable-wrapper {
  height: 100%;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.25rem 0.25rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.25rem 0.25rem;
  text-align: center;
}

.p-datatable .p-datatable-header {
  padding: 0.25rem 0.25rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
}

.p-radiobutton-box {
  border: solid 1px black !important;
}

span.p-ripple.p-element.p-button.p-component.p-fileupload-choose {
  background-color: #0284c7;
  padding: 0.5rem 1rem;
  --tw-border-opacity: 1;
  border-color: #0284c7;
  border-radius: 0.25rem;
  border-width: 1px;
}

span.p-ripple.p-element.p-button.p-component.p-fileupload-choose:hover {
  background-color: #075985;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  outline: none;
  background: var(--gray-400);
  border-radius: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: var(--gray-400);
  cursor: pointer;
  border-radius: 50%;
}
