.success-snack-bar{
  --mdc-snackbar-supporting-text-color: white;
  --mdc-snackbar-container-color:var(--green-5);
}

.mdc-snackbar .mat-mdc-snack-bar-label
{
  --mdc-snackbar-supporting-text-size: 1.1rem;
  text-align: center;
}

.error-snack-bar{
  --mdc-snackbar-supporting-text-color: white;
  --mdc-snackbar-container-color:var(--red-5);
}