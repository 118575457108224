@import url("./app/shared/directives/tooltip/esk-tooltip.css") layer(utilities);
@import url("./styles/reset.css") layer(reset);
@import url("../node_modules/ol/ol.css") layer(components);
@import url("./styles/forms.css") layer(utilities);
@import url("../node_modules/open-props/open-props.min.css") layer(utilities);
@import url("./styles/toast.css") layer(utilities);
@import url("@angular/material/prebuilt-themes/azure-blue.css") layer(material);
@import url("../node_modules/@fortawesome/fontawesome-free/css/all.min.css")
layer(utilities);
@import url("./app/@core/map/ui/sidebar/sidebar.component.css")
layer(components);
@import url('./app/@admin/styles/admin.css');
@import url('./app/@core/map/styles/bottom-drawer.css');
@import url('./app/@core/map/styles/map.css');
@import url('./app/@core/map/styles/prime-ng-override.css');
@import url('./styles/dialog.css') layer(components);
@import url('./styles/data-catalogue.css') layer(components);
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@layer reset, material,components, utilities, overrides;

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --scrollbar-width: 4px;
  --scrollbar-thumb-border-radius: calc(var(--scrollbar-width) / 2);
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: var(--scrollbar-thumb-border-radius);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Scrollbar for Firefox */
/* These styles only work in Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

*::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

*::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: var(--scrollbar-thumb-border-radius);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

html,
body {
  height: 100dvh;
  width: 100dvw;
}
body {
  margin: 0;
  font-family: "Outfit", sans-serif;
  display: grid;
}

.map {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  :root {
    --mat-sidenav-container-shape: 0;
  }
}

.mat-drawer.sidebar-drawer {
  overflow-y: unset;
}

.mat-drawer.sidebar-drawer .mat-drawer-inner-container {
  overflow: unset;
}
.mdc-notched-outline--notched .mdc-notched-outline__notch {
  border-right: none;
}

.mdc-notched-outline__notch {
  border-right: none;
}

.bg-gradient {
  background: linear-gradient(
    135deg,
    rgba(173, 216, 230, 0.6),
    rgba(144, 238, 144, 0.6)
  );
  backdrop-filter: blur(10px);
}
