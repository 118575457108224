.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 1rem;
}

.form-container h1 {
  font-size: 2em;
}

.form-container form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-container .actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.form-container .button {
  padding: 10px;
  border: none;
  background-color: #555;
  color: #fff;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.form-container .button:hover {
  background-color: #333;
}

.form-container .button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.form-container .error-message {
  color: #d32f2f;
  background-color: #ffebee;
  padding: 10px;
  margin-top: 1rem;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ffcdd2;
}

.form-container footer {
  color: var(--gray-4);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
}
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  border-right: 1px solid #dee2e6;
}

.p-datatable .p-datatable-thead > tr > th:last-child,
.p-datatable .p-datatable-tbody > tr > td:last-child {
  border-left: 1px solid #dee2e6;
}

p-table {
  display: block;
}

.p-datatable {
  height: 100%;
  font-size: 0.9rem;
}

.p-datatable .p-datatable-wrapper {
  height: 100%;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.05rem 0.05rem;
  text-align: center;
}

.p-datatable .p-datatable-header {
  padding: 0.5rem 0.5rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
}

.smaller-fields
  .mat-mdc-text-field-wrapper
  .mat-mdc-form-field-flex
  .mat-mdc-floating-label {
  transform: unset;
}
