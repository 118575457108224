.p-listbox-list {
  list-style: none none !important;
  cursor: pointer !important;
}

.p-listbox-list-wrapper {
  max-height: 100% !important;
  display: block;
  width: 100%;
}

.p-listbox-item {
  font-size: 14px;
  position: relative;
  padding-inline: 1rem;
  height: 48px;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

input.p-element.p-autocomplete-input.p-inputtext.p-component {
  width: 100%;
  height: 50px;
  border: solid 1px black;
  padding-left: 10px;
}

div#options {
  height: 50px;
}

input.p-inputtext.p-component.p-element.ng-star-inserted {
  height: 50px;
  min-width: 85px;
}

.p-accordion-content	{
  background-color: #fdfbff !important;
}

.p-accordion-header-link {
  height: 50px;
}

.p-accordion-header-link:hover {
  background: var(--stone-2);
}

.p-accordion-header-link[aria-expanded=true] {
  background: var(--stone-4);
}

.small-input-switch {
  .p-inputswitch {
    margin-top: 6px;
    height: 1.2rem;
    width: 2.4rem;
    &.p-inputswitch-checked {
      .p-inputswitch-slider::before {
        transform: translateX(1rem);
      }
    }
    .p-inputswitch-slider::before {
      width: 0.9rem;
      height: 0.9rem;
      margin-top: -0.45rem;
    }
  }
}

.p-accordion-header-text {
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: normal !important;
}

.p-multiselect-item	{
  font-size: 13.25px;
}

.p-card-body {
  position: absolute;
  top: 0;
  z-index: 1001;
  background: white;
  height: 45px;
  left: 37.5vw;
  padding: 10px !important;
}

.p-card .p-card-title {
  font-size: 1rem;
  font-weight: normal;
}

