.txt-area {
  max-height: 24rem;
  overflow-y: auto !important;
  padding: 5px 10px;
  width: 100%;
  border: solid 1px black;
}

.readonly-input {
  --disabled: #94a3b8;
  --disabledBg: #f1f5f9;
  border: solid 1px var(--disabled);
  background-color: var(--disabledBg);
  color: var(--disabled);
  padding: 5px 10px;
  width: 100%;
}

.layer-title {
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: normal !important;
}

.matrix-result {
  padding: 2px;
  width: 10rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.matrix-value {
  color: white;
  text-shadow: 2px 2px #000000;
  font-weight: bold;
  font-size: 0.875rem; /*14px*/
}

.matrix-lbl {
  font-size: 12px;
}
